import React from "react"

type Props = {
  label: string
  name: string
  placeholder?: string
  className?: string
}

const TextField = ({ className, label, placeholder, name }: Props) => {
  return (
    <div className={`px-3 mb-6 md:mb-0 ${className}`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label} <span className="text-red-500">*</span>
      </label>
      <textarea
        name={name}
        rows={5}
        placeholder={placeholder}
        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      />
    </div>
  )
}

export default TextField
