import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import InputField from "../components/form/Input-field"
import SelectField from "../components/form/Select-field"
import TextField from "../components/form/text-field"

const Afspraak = () => (
  <Layout title="Afspraak">
    <PageTitle title="Maak een afspraak" />

    <form
      name="Afspraak"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      action="/success/"
      className="w-full max-w-3xl bg-white shadow-sm rounded px-8 pt-6 pb-8 my-12 mx-auto"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="Afspraak" />
      <div className="flex flex-wrap mx-3 mb-6">
        <InputField
          label="Roepnaam"
          name="roepnaam"
          className="w-full md:w-1/2"
        />
        <InputField
          label="Achternaam"
          name="achternaam"
          className="w-full md:w-1/2"
        />
      </div>
      <div className="flex flex-wrap mx-3 mb-6">
        <InputField
          label="Straat en Huisnummer"
          name="straat"
          placeholder="Voorbeeldstraat 1"
          className="w-full md:w-2/5"
        />
        <InputField
          label="Woonplaats"
          name="woonplaats"
          placeholder="Wijnandsrade"
          className="w-full md:w-2/5"
        />
        <InputField
          label="Postcode"
          name="postcode"
          placeholder="1234AB"
          className="w-full md:w-1/5"
        />
      </div>
      <div className="flex flex-wrap mx-3 mb-6">
        <InputField
          label="Telefoon"
          name="telefoon"
          placeholder="0451234567"
          className="w-full md:w-1/2"
        />
        <SelectField
          label="Geslacht"
          name="geslacht"
          className="w-full md:w-1/2"
        >
          <option value="man">Man</option>
          <option value="vrouw">Vrouw</option>
        </SelectField>
      </div>
      <div className="flex flex-wrap mx-3 mb-6">
        <InputField
          label="E-mailadres"
          name="email"
          placeholder="voorbeeld@voorbeeld.nl"
          className="w-full"
        />
      </div>
      <div className="flex flex-wrap mx-3 mb-6">
        <TextField
          label="Korte klachtenomschrijving"
          name="klachtenomschrijving"
          className="w-full"
        />
      </div>
      <div className="flex flex-wrap mx-3 mb-6">
        <button type="submit" className="btn btn-primary mx-3">
          Verzend
        </button>
      </div>
      <small className="mx-6">
        (<span className="text-red-500">*</span>) Verplichte velden
      </small>
    </form>
  </Layout>
)

export default Afspraak
